import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from 'components/Layout';
import Seo from 'components/Seo';

const NotFound = () => (
  <Layout>
    <Seo title="404 Not Found" />
    <Main>
      <h1>404 Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/">Back to home</Link>
    </Main>
  </Layout>
);

const Main = styled.div`
  margin: 2rem 0;
`;

export default NotFound;
